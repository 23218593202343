export const config = {
  scores: {
    LOC_SCORE: process.env.REACT_APP_LOC_SCORE,
    BANK_SCORE: process.env.REACT_APP_BANK_SCORE,
    TOKEN_DISTRIBUTION_SCORE: process.env.REACT_APP_TOKEN_DISTRIBUTION_SCORE,
    BRIBE_SCORE: process.env.REACT_APP_BRIBE_SCORE,
    LAND_SCORE: process.env.REACT_APP_LAND_SCORE,

    CROWN_SCORE: process.env.REACT_APP_CROWN_SCORE,
    VESTING_SCORE: process.env.REACT_APP_VESTING_SCORE,
    CROWN_CLAIM_SCORE: process.env.REACT_APP_CROWN_CLAIM_SCORE,
    GOLDEN_KEY_SCORE:
      process.env.REACT_APP_GOLDEN_KEY_SCORE || 'cx42ca248689765f31f74eda4a60ee9bcda94a934f',
  },
  reserves: {
    STAKING_RESERVE: process.env.REACT_APP_STAKING_RESERVE,
    LP_RESERVE: process.env.REACT_APP_LP_RESERVE,
    AIRDROP_RESERVE: process.env.REACT_APP_AIRDROP_RESERVE,
  },
  events: {
    MINTING_LOC: 'CertificateMint(Address,int,int,bool)',
    DEPOSIT_INTO_BANK: 'Deposit(Address,int)',
    WITHDRAW_FROM_BANK: 'Withdraw(Address,int)',
    APPROVAL_FOR_ALL: 'ApprovalForAll(Address,Address,bool)',
    MINT_LAND: 'LandMint(int,int,str,Address,str)',
  },
  icon: {
    WALLET_URL: process.env.REACT_APP_WALLET_URL,
    NETWORK_ID: process.env.REACT_APP_NETWORK_ID,
  },
};

export const test = {};
