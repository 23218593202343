import {FOOTER} from '@constants/images';

const Footer = () => {
  const discord = 'https://discord.gg/eTfkQxKst3';
  const twitter = 'https://x.com/GangstaVerse';
  // const telegram = 'https://t.me/gangstabet_icon';
  const medium = 'https://gangstaverse.medium.com/';

  return (
    <footer className="footer-wrapper">
      <div className="footer-copyright">{/* &copy;<span> GANGSTABET</span> */}</div>
      <div className="footer-media">
        <button
          onClick={() => window.open(discord, '_blank')}
          type="button"
          className="borderless-btn">
          <img src={FOOTER.DISCORD} alt="footer-discord" />
        </button>
        {/* <button
          onClick={() => window.open(telegram, '_blank')}
          type="button"
          className="borderless-btn">
          <img src={FOOTER.TELEGRAM} alt="footer-telegram" />
        </button> */}
        <button
          onClick={() => window.open(twitter, '_blank')}
          type="button"
          className="borderless-btn">
          <img src={FOOTER.TWITTER} alt="footer-teitter" />
        </button>
        <button
          onClick={() => window.open(medium, '_blank')}
          type="button"
          className="borderless-btn">
          <img src={FOOTER.MEDIUM} alt="footer-medium" />
        </button>
      </div>
    </footer>
  );
};

export default Footer;
