import Button from '@components/common/Button.component';
import StylishDecor from '@components/common/decorators/StylishDecor.component';
import BridgeSuccess from '@components/modal/BridgeSuccess.modal';
import {HEADER} from '@constants/images';
import {setAvailableGoldenKey} from '@redux/features/wallet/walletSlice';
import {RootState} from '@redux/store';
import {burnGoldenKey, getGoldenKeysOf, getTransactionResult} from '@services/Icon.service';
import {toFixedNoRounding} from '@services/Misc.service';
import {useEffect, useState} from 'react';
// import {FiExternalLink} from 'react-icons/fi';
import {useDispatch, useSelector} from 'react-redux';
import {ImPaste} from 'react-icons/im';

const ForexLiveSoon = () => {
  const {
    isWalletConnected,
    walletAddress,
    availableGoldenKey,
    ledgerTransport,
    ledgerPath,
    isLedger,
  } = useSelector((state: RootState) => state.inventory);
  const [loading, setLoading] = useState(false);
  const [goldenKeyInput, setGoldenKeyInput] = useState('');
  const [suiAddress, setSuiAddress] = useState('');
  const [errorMsg, setErrorMsg] = useState({
    field: '',
    message: '',
  });

  const [successMessage, setSuccessMessage] = useState('');
  const [successModal, setSuccessModal] = useState(false);

  const dispatch = useDispatch();

  const eventHandler = (event: any) => {
    if (event.detail.type === 'RESPONSE_JSON-RPC') {
      // eslint-disable-next-line no-use-before-define
      getTxResultWithTimer(event.detail.payload.result);
    } else if (event.detail.type === 'CANCEL_JSON-RPC') {
      // dispatch(setBankLoading(false));
      console.log('Cancelled');
      setLoading(false);
      setSuccessMessage('Bridging Cancelled');
      setTimeout(() => {
        setSuccessMessage('');
      }, 3000);
      setLoading(false);
    }
  };

  useEffect(() => {
    window.addEventListener('ICONEX_RELAY_RESPONSE', eventHandler, false);
    return () => {
      window.removeEventListener('ICONEX_RELAY_RESPONSE', eventHandler);
    };
  }, []);

  const validateInputs = () => {
    if (!goldenKeyInput) {
      setErrorMsg({field: 'key', message: 'Please enter the number of keys.'});
      return false;
    }
    if (Number(goldenKeyInput) > Number(availableGoldenKey)) {
      setErrorMsg({
        field: 'key',
        message: `Enter valid number of golden keys < ${availableGoldenKey}.`,
      });
      return false;
    }
    if (Number(goldenKeyInput) <= 0) {
      setErrorMsg({field: 'key', message: 'Enter a valid number of keys greater than 0.'});
      return false;
    }
    if (!suiAddress) {
      setErrorMsg({field: 'sui', message: 'Please enter your Sui address.'});
      return false;
    }
    if (!/^(0x)?[0-9a-fA-F]{64}$/.test(suiAddress)) {
      setErrorMsg({
        field: 'sui',
        message: 'Enter a valid Sui address (64-character hexadecimal string starting with 0x).',
      });
      return false;
    }
    setErrorMsg({field: '', message: ''});
    return true;
  };

  const getTx = async (tx: string) => {
    const res: unknown | any = await getTransactionResult(tx);

    if (res.status === 'pending') {
      // eslint-disable-next-line no-use-before-define
      getTxResultWithTimer(tx);
      console.log('pending');
    } else if (res?.status === 'success') {
      // setSuccessMessage('Bridging Successful');
      setSuccessModal(true);
      const goldenKeys = await getGoldenKeysOf(walletAddress);
      console.log({goldenKeys});
      dispatch(setAvailableGoldenKey(toFixedNoRounding(Number(goldenKeys), 4) as string));

      setLoading(false);

      setTimeout(() => {
        setSuccessMessage('');
      }, 3000);
    } else {
      setSuccessMessage('Bridging Failed');
      setTimeout(() => {
        setSuccessMessage('');
      }, 3000);
      setLoading(false);
    }
  };

  const getTxResultWithTimer = (txHash: string) => {
    setTimeout(() => {
      getTx(txHash);
    }, 5000);
  };

  const handleBridge = async () => {
    try {
      if (!validateInputs()) {
        return;
      }
      setLoading(true);

      if (walletAddress && isWalletConnected) {
        const tx: any = await burnGoldenKey(
          suiAddress,
          walletAddress,
          goldenKeyInput,
          ledgerTransport,
          ledgerPath,
          isLedger
        );

        if (isLedger) {
          if (tx?.name === 'TransportStatusError') {
            console.log('Ledger cancelled!!!');
          } else if (tx) {
            getTxResultWithTimer(tx as string);
          } else {
            console.log('Error on ledger');
          }
        }
      }

      // setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handlePaste = async () => {
    try {
      const clipboardText = await navigator.clipboard.readText();
      console.log('Pasted content:', clipboardText);

      setSuiAddress(clipboardText);
    } catch (error) {
      console.error('Failed to read clipboard content:', error);
    }
  };
  return (
    <StylishDecor height="30rem" className="forexLiveSoon-wrapper" style={{margin: 0}}>
      <div className="forex-live-text-div">
        <div className="forex-upper-box">
          <img width={40} src={HEADER.GOLDEN_KEY} alt="" />
          <p>User Balance: {availableGoldenKey}</p>
        </div>
        <div className="forex-live-form-container">
          <form>
            <div className="forex-form-group">
              <p>How many keys do you want to bridge?</p>
              <input
                placeholder="Enter no of keys"
                className="forex-input"
                type="text"
                onChange={(e) => setGoldenKeyInput(e.target.value)}
                value={goldenKeyInput}
                id="goldenKey"
              />
              {errorMsg.field === 'key' && <p className="forex-error-msg">{errorMsg.message}</p>}
            </div>
            <div className="forex-form-group">
              <p>Enter your Sui address:</p>
              <input
                placeholder="Enter address"
                className="forex-input"
                type="text"
                onChange={(e) => setSuiAddress(e.target.value)}
                value={suiAddress}
                id="suiAddress"
              />
              <div className="forex-input-addonIcon">
                <button title="Paste address" onClick={handlePaste} type="button">
                  <ImPaste size={18} color="#fff" />
                </button>
              </div>
              {errorMsg.field === 'sui' && <p className="forex-error-msg">{errorMsg.message}</p>}
            </div>
            <Button
              style={{width: '100%'}}
              isProgress={loading}
              onClick={handleBridge}
              className="primary"
              text="Bridge"
              disabled={loading || !isWalletConnected}
            />

            {successMessage && <p className="forex-fail-text">{successMessage}</p>}
          </form>
        </div>

        {/* <div className="bridge-disclaimer-text">
          <p>
            <strong>Note:</strong> The bridge for migrating Golden Keys from ICON to Sui will close
            on March 24, 2025. After this date, please contact our team on Discord for assistance
            with key migration.
          </p>
        </div> */}
      </div>

      {successModal && (
        <BridgeSuccess
          setSuccessModal={setSuccessModal}
          extraFunctions={() => {
            setGoldenKeyInput('');
            setSuiAddress('');
          }}
          suiAddress={suiAddress}
          amount={goldenKeyInput}
        />
      )}
    </StylishDecor>
  );
};
export default ForexLiveSoon;
