import {
  certificateMintCost,
  crownPerXCrown,
  minimumDepositAmount,
  depositIntoBank,
  mintWithCrown,
  withdrawFromBank,
  getTotalXCrownByUser,
  mintWithCrownAndBribe,
  getBalanceOf,
} from '@services/Icon.service.js';

export const getCertificateMintCost = async () => {
  const mintCost = await certificateMintCost();
  return mintCost;
};

export const getCrownPerXCrown = async () => {
  const price = await crownPerXCrown();
  return price;
};

export const getAvailableCrown = async (address: string) => {
  const crown = await getBalanceOf(address);
  return crown;
};

export const getMinimumDepositAmount = async () => {
  const crown = await minimumDepositAmount();
  return crown;
};

export const depositCrownIntoBank = async (
  address: string,
  amount: number,
  ledgerTransport: any,
  ledgerPath: any,
  isLedger: boolean
) => {
  const txHash = depositIntoBank(address, amount, ledgerTransport, ledgerPath, isLedger);
  return txHash;
};

export const withdrawCrownFromBank = async (
  address: string,
  amount: number,
  ledgerTransport: any,
  ledgerPath: any,
  isLedger: boolean
) => {
  const txHash = withdrawFromBank(address, amount, ledgerTransport, ledgerPath, isLedger);
  return txHash;
};

export const mintViaCrown = async (
  address: string,
  mintCount: number,
  totalMintCost: number,
  ledgerTransport: any,
  ledgerPath: any,
  isLedger: boolean
) => {
  const txHash = await mintWithCrown(
    address,
    mintCount,
    totalMintCost,
    ledgerTransport,
    ledgerPath,
    isLedger
  );
  return txHash;
};

export const mintViaCrownAndBribe = async (
  address: string,
  mintCount: number,
  bribeTokenId: string,
  bribeTokenCount: string,
  ledgerTransport: any,
  ledgerPath: any,
  isLedger: boolean
) => {
  const txHash = await mintWithCrownAndBribe(
    address,
    mintCount,
    bribeTokenId,
    bribeTokenCount,
    ledgerTransport,
    ledgerPath,
    isLedger
  );
  return txHash;
};

export const getTotalXCrown = async (address: string) => {
  try {
    const totalXCrown: any = getTotalXCrownByUser(address);
    return totalXCrown;
  } catch (e) {
    return 0;
  }
};
