import {useState, useEffect, useRef} from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import type {RootState} from '@redux/store';
import ReactTooltip from 'react-tooltip';

import {IoIosArrowDown} from 'react-icons/io';
import {GiHamburgerMenu} from 'react-icons/gi';
import {AiOutlineClose} from 'react-icons/ai';
import {TbExternalLink} from 'react-icons/tb';

import {HEADER} from '@constants/images';
import ConnectWith from '@components/modal/ConnectWith.modal';
import DisconnectWarn from '@components/modal/DisconnectWarn.modal';
import WalletSuccess from '@components/modal/WalletSuccess.modal';

import {
  setAvailableCrown,
  setLedgerPath,
  setLedgerTransport,
  setWalletAddress,
  setWalletConnection,
  setIsLedger,
  setAvailableGoldenKey,
} from '@redux/features/wallet/walletSlice';
import {getAvailableCrown} from '@services/Crown.service';
import {getGoldenKeysOf} from '@services/Icon.service';
import {toFixedNoRounding} from '@services/Misc.service';
import {setMintCount, setRecentMintedNFT} from '@redux/features/mint/mintSlice';

const Header = () => {
  const [conectionDropdown, setConectionDropdown] = useState<boolean>(false);
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const menuRef = useRef<any>();
  // const [getInventoryLocation, setInventoryLocation] = useState('');
  const [connectWalletPopup, setConnectWalletPopup] = useState<boolean>(false);
  // const [goldenKeyCount, setGoldenKeyCount] = useState<number>(0);
  const {isWalletConnected, walletAddress, availableCrown, availableGoldenKey, isLedger} =
    useSelector((state: RootState) => state.inventory);
  const [connectWalletModal, setConnectWalletModal] = useState(false);
  const [disconnectWalletModal, setDisconnectWalletModal] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const eventHandler = (event: any) => {
    if (event.detail.type === 'RESPONSE_ADDRESS') {
      dispatch(setWalletConnection(true));
      dispatch(setWalletAddress(event.detail.payload));
      setConnectWalletModal(true);
      if (window.location.href.toString().includes('/inventory/')) {
        navigate(`/inventory/${event.detail.payload}`);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('ICONEX_RELAY_RESPONSE', eventHandler, false);
    return () => {
      window.removeEventListener('ICONEX_RELAY_RESPONSE', eventHandler);
    };
  }, []);

  useEffect(() => {
    if (isLedger) {
      // Disconnect ledger
      dispatch(setIsLedger(false));
      dispatch(setWalletAddress(null));
      dispatch(setLedgerPath(null));
      dispatch(setLedgerTransport(null));
      dispatch(setWalletConnection(false));
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (isWalletConnected && walletAddress) {
        const availableCrownAmt = await getAvailableCrown(walletAddress);
        const goldenKeys = await getGoldenKeysOf(walletAddress);
        dispatch(setAvailableCrown(toFixedNoRounding(Number(availableCrownAmt), 4) as string));
        dispatch(setAvailableGoldenKey(toFixedNoRounding(Number(goldenKeys), 4) as string));

        // setGoldenKeyCount(await getGoldenKeysOf(walletAddress));
      }
    })();
  }, [isWalletConnected]);

  useEffect(() => {
    const handler = (event: any) => {
      if (!menuRef.current.contains(event.target)) {
        setConectionDropdown(false);
      }
    };
    document.addEventListener('mousedown', handler);
    return () => {
      document.removeEventListener('mousedown', handler);
    };
  });

  const disconnectUserWallet = () => {
    dispatch(setWalletConnection(false));
    dispatch(setWalletAddress(null));
    setDisconnectWalletModal(false);
    // eslint-disable-next-line
    window.location.href.toString().includes('/inventory/') && navigate('/inventory/connect');
  };
  const VIEW_MAP_URL = 'https://map.emeraldcity.xyz/';
  return (
    <div className="main-wrapper">
      <div className="main-header">
        <div className="header-logo">
          <Link
            onClick={() => {
              setShowMenu(false);
              dispatch(setRecentMintedNFT([]));
              dispatch(setMintCount(1));
            }}
            to="/">
            <img src={HEADER.LOGO} alt="Logo" />
          </Link>
        </div>

        <div
          className={`header-content ${
            showMenu ? 'header-content-menu' : 'header-content-menu-deactivate'
          }`}>
          <div className="header-item">
            <Link
              onClick={() => {
                window.open('https://gangstaverse.co/');
              }}
              className="links header-body-content"
              to="#">
              GangstaVerse <TbExternalLink />
            </Link>
          </div>
          <div className="header-item">
            <Link
              onClick={() => {
                setShowMenu(false);
              }}
              className={`links header-body-content ${
                location.pathname.toString() === '/bridge' && 'active-class'
              }`}
              to="/bridge">
              Golden Key Bridge
            </Link>
          </div>
          <div className="header-item">
            <Link
              onClick={() => {
                setShowMenu(false);
              }}
              className={`links header-body-content ${
                location.pathname.toString() === '/crown' && 'active-class'
              }`}
              to="/crown">
              CROWN
            </Link>
          </div>
          <div className="header-item">
            <Link
              onClick={() => {
                setShowMenu(false);
              }}
              className={`links header-body-content ${
                location.pathname.toString() === '/bank' && 'active-class'
              }`}
              to="/bank">
              Bank
            </Link>
          </div>

          <div className="header-item">
            <Link
              onClick={() => {
                setShowMenu(false);
              }}
              className={`links header-body-content ${
                location.pathname.toString().includes('inventory') && 'active-class'
              }`}
              to={`${isWalletConnected ? `/inventory/${walletAddress}` : '/inventory/connect'}`}>
              Inventory
            </Link>
          </div>
          <div className="header-item">
            <Link
              onClick={() => {
                setShowMenu(false);
              }}
              className={`links header-body-content ${
                location.pathname.toString().includes('lands') && 'active-class'
              }`}
              to="/lands">
              Lands
            </Link>
          </div>
          {/* <div className="header-item">
            <Link
              onClick={() => {
                setShowMenu(false);
              }}
              className="links header-body-content"
              to="/profile">
              Profile
            </Link>
          </div> */}
          {/* <div className="header-item">
            <Link
              onClick={() => {
                setShowMenu(false);
              }}
              className="links header-body-content"
              to="/marketplace">
              Marketplace
            </Link>
          </div> */}
          {/* eslint-disable-next-line */}
          <div
            onClick={() => {
              window.open(VIEW_MAP_URL, '_blank');
            }}
            className="header-boxes header-show-map">
            <img src={HEADER.MAP} alt="map logo" />
            <p
              // data-tip="Releasing soon."
              // data-effect="solid"
              // data-place="top"
              className="header-body-content header-map-box">
              View map
            </p>
          </div>
          {/* eslint-disable-next-line */}
          <div
            ref={menuRef}
            onClick={() => {
              setConectionDropdown(!conectionDropdown);
            }}
            className="header-boxes header-wallet-connection">
            <img src={HEADER.ICON} alt="map logo" />
            {isWalletConnected && walletAddress ? (
              <div>
                <p className="header-body-content header-icon-box">
                  {walletAddress.slice(0, 6)}...
                </p>
              </div>
            ) : (
              <p className="header-body-content header-icon-box">Connect</p>
            )}
            <IoIosArrowDown color="#C9CCD9" />
            {conectionDropdown && (
              <div className="header-wallet-conn-options">
                {!isWalletConnected ? (
                  <button
                    className="con-btn-header"
                    type="button"
                    onClick={() => {
                      setConnectWalletPopup(true);
                      setShowMenu(false);
                    }}>
                    Connect
                  </button>
                ) : (
                  <button
                    className="con-btn-header"
                    type="button"
                    onClick={() => {
                      setDisconnectWalletModal(true);
                      setShowMenu(false);
                    }}>
                    Disconnect
                  </button>
                )}
              </div>
            )}
          </div>
          {isWalletConnected && walletAddress && (
            <div className="crown-key-wrapper">
              <div className="header-crown">
                <img src={HEADER.CROWN} alt="crown" />
                <p className="header-crown-text">
                  {toFixedNoRounding(availableCrown).toLocaleString()}
                </p>
              </div>

              <div className="header-crown">
                <img src={HEADER.GOLDEN_KEY} alt="crown" width={18} />
                <p className="header-crown-text">
                  {toFixedNoRounding(availableGoldenKey).toLocaleString()}
                </p>
              </div>
            </div>
          )}
        </div>
        <div className="hamburger">
          {!showMenu ? (
            <GiHamburgerMenu
              onClick={() => {
                setShowMenu(!showMenu);
              }}
              size={20}
            />
          ) : (
            <AiOutlineClose
              onClick={() => {
                setShowMenu(!showMenu);
              }}
              size={22}
            />
          )}
        </div>
      </div>
      {connectWalletPopup && <ConnectWith setConnectWalletPopup={setConnectWalletPopup} />}
      {/* eslint-disable */}
      <ReactTooltip className="opaque" multiline={true} />
      {connectWalletModal && (
        <WalletSuccess
          setConnectWalletModal={setConnectWalletModal}
          walletAddress={walletAddress}
        />
      )}
      {disconnectWalletModal && (
        <DisconnectWarn
          setDisconnectWalletModal={setDisconnectWalletModal}
          disconnectUserWallet={disconnectUserWallet}
        />
      )}
    </div>
  );
};

export default Header;
