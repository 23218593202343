import Modal from '@components/modal/Modal.component';
import Button from '@components/common/Button.component';

const EMERALD_CITY_SUI =
  process.env.REACT_APP_EMERALD_CITY_SUI_URL || 'https://emerald-sui-dev.netlify.app/';

const BridgeSuccess = ({setSuccessModal, suiAddress, amount, extraFunctions}: any) => {
  const tradepotAssetLink =
    'https://www.tradeport.xyz/sui/collection/0x78ddda067d8f156129542d332b5da29c2308c42e091390cbdb226767fad5584c?tab=items&bottomTab=trades';

  return (
    <Modal title="Congratulations!" setPopupOpen={setSuccessModal} extraFunctions={extraFunctions}>
      <div className="connect-success">
        <p style={{marginBottom: '6px'}} className="text-center wallet-connected-success">
          Successfully migrated <br /> {amount} Golden Key{Number(amount) > 1 && 's'} to
        </p>
        <p style={{marginBottom: '6px'}} className="text-center wallet-connected-address">
          {suiAddress.slice(0, 6)}...
        </p>
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <Button
            onClick={() => {
              window.open(tradepotAssetLink, '_blank');
            }}
            style={{width: '200px'}}
            className="primary"
            text="View on TradePort"
          />
        </div>
        <div
          style={{
            display: 'flex',
            marginTop: '1rem',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <Button
            onClick={() => {
              window.open(EMERALD_CITY_SUI, '_blank');
            }}
            style={{width: '200px'}}
            className="primary"
            text="Go to Emerald City"
          />
        </div>
      </div>
    </Modal>
  );
};
export default BridgeSuccess;
